import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserInterface } from "../../utility/interface/api/users/get-user-res";

const initialState: UserInterface = {
  id: "",
  user_id: "",
  is_provider: false,
  is_initialized: false,
  profile: {
    email: "",
    verified_email: false,
    name: "",
    given_name: "",
    family_name: "",
    picture: "",
    hd: "",
  },
  token: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserInfo: (state, action: PayloadAction<UserInterface>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return action.payload;
    },
    updateToken: (state, action: PayloadAction<any>) => {
      return { ...state, token: { ...action.payload } };
    },
    removeUserInfo: (state) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUserInfo, updateToken, removeUserInfo } =
  userSlice.actions;

export default userSlice.reducer;
