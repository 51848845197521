import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import "../../../assets/scss/instance-card-style.scss";
import { Button, Divider, Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import {
  getFilesListFromGoogleDrive,
  getGoogleDriveOAuth,
  uploadToGoogleDrive,
} from "../../server/GoogleDrive";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { GoogleDriveCard } from "../../components/GoogleDriveCard";
import { updateMenuKey } from "../../features/settings/settingsSlice";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const cloudRoute = process.env.REACT_APP_CLOUD_REDIRECT_ROUTE;

export const Cloud = () => {
  const { t } = useTranslation();
  const userState = useAppSelector((state: any) => state.user);
  const [loading, setLoading] = useState<boolean>(true);
  const [connect, setConnect] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any>([]);
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState<string>("");
  let [searchParams, setSearchParams] = useSearchParams();
  let state = searchParams.get("state");
  const dispatch = useAppDispatch();

  const uploadToGoogle = (file_name: string) => {
    let treatedState = state?.split("|user_id")[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", file_name);

    uploadToGoogleDrive(treatedState!, formData);
  };

  const uploadingFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const getFiles = async () => {
    let treatedState = state?.split("|user_id")[0];

    getFilesListFromGoogleDrive(treatedState!, {
      page_size: 20,
    }).then((res) => {
      setFileList(res.data.files);
    });
  };

  useEffect(() => {
    if (state !== null) {
      dispatch(updateMenuKey("4"));
      getFiles();
      setConnect(true);
      setLoading(false);
    }
  }, [state]);

  return (
    <>
      {!state && (
        <Button
          type="primary"
          href={`${baseURL}getGoogleDriveOAuth?urlName=${cloudRoute}&user_id=${userState.user_id}`}
        >
          <img src={`./images/GoogleDriveBtn/btn.png`} width={14} />
          {t("Connect to Google Drive")}
        </Button>
      )}
      {state && fileList.length === 0 && (
        <div className="loading-wrapper">
          <Spin
            indicator={
              <LoadingOutlined
                spin
                style={{ fontSize: 72, marginBottom: ".5em" }}
              />
            }
          />
          <Space />
          <div>{t("Fetching files...")}</div>
        </div>
      )}
      {connect && (
        <div className="file-upload">
          <input type="text" onChange={(e) => setFileName(e.target.value)} />
          <input type="file" onChange={uploadingFile} />
          <Button
            type="primary"
            disabled={file == undefined}
            onClick={() => {
              uploadToGoogle(fileName);
            }}
          >
            <img src={`./images/GoogleDriveBtn/btn.png`} width={14} />
            {t("Upload to Google Drive")}
          </Button>
        </div>
      )}
      {connect && (
        <>
          {fileList.length !== 0 && <h1>File List</h1>}
          <div className="google-drive-card-container">
            {fileList.map((item: any) => {
              return (
                <GoogleDriveCard
                  id={item.id}
                  name={item.name}
                  createdTime={item.createdTime}
                  modifiedTime={item.modifiedTime}
                  mimeType={item.mimeType}
                  userState={state!}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};
