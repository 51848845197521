// TODO: Create redux for rental + payment alongside with rentGPU for cross plat
// talk to Harrison about payment system process

import "../../../assets/scss/payment-style.scss";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Divider,
  Radio,
  RadioChangeEvent,
  Select,
  Skeleton,
  Tag,
} from "antd";

import { CheckOutlined } from "@ant-design/icons";

import {
  ApiOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  DatabaseOutlined,
  DesktopOutlined,
  RobotOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { RentalCardInterface } from "../../utility/interface/rental-card";

// fake data section

const cards = [
  {
    id: 0,
    type: "visa",
    digit: "9932",
    value: "visa9932",
  },
  {
    id: 1,
    type: "visa",
    digit: "1917",
    value: "visa1917",
  },
];

const fakeData: RentalCardInterface = {
  machine_id: 0,
  product_name: "NVIDIA RTX 4090",
  price: 0.32,
  gpu_count: 32,
  gpu_ram: "21",
  cpu_name: "6× Xeon E5-2680 v4",
  internet_upload_speed: 1967,
  internet_download_speed: 6222,
  effective_ram: "128",
  disk_max_available_storage: 1024,
};

export const Payment = () => {
  const { t } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState<string>("card"); // 付款方式 tab
  const [paymentMethodSelect, setPaymentMethodSelect] = useState<string>(""); // 選擇付款方式
  const [totalPrice, setNewTotalPrice] = useState<number | string>(
    Number(fakeData.price)
  ); // 總價
  const [options, setOptions] = useState<any[]>([]); // 可選張數
  const [amount, setNewAmount] = useState<number>(1); // 選擇張數
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const paymentMethods = [
    { label: t("Credit CardDebit Card"), value: "card" },
    { label: t("Bank Account"), value: "bank" },
    { label: t("Online Services"), value: "onlineService" },
  ];

  const banks = [
    {
      id: "1",
      name: t("Yushan Bank"),
      value: "yushan",
      confirmed: true,
    },
    {
      id: "2",
      name: t("China Trust"),
      value: "chinatrust",
      confirmed: true,
    },
    {
      id: "3",
      name: t("TaiShin Bank"),
      value: "taihsin",
      confirmed: false,
    },
  ];

  const confirmPayment = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setPaymentMethodSelect("");
      navigate("/instances");
    }, 1000);
  };

  useEffect(() => {
    // this creates the select options for amt
    let optionArray = Array.from(
      { length: Number(fakeData.gpu_count) },
      (_, i) => ({
        value: i + 1,
        label: i + 1,
      })
    );
    setOptions(optionArray);
  }, []);

  useEffect(() => {
    let newPrice = Number(fakeData.price) * amount;
    let decimals = newPrice.toFixed(2);
    setNewTotalPrice(decimals);
  }, [amount]);

  return (
    <>
      {loading ? (
        <div className="wrapper">
          <h4>{t("Order Details")}</h4>
          <Skeleton active />
        </div>
      ) : (
        <div className="wrapper">
          <h4>{t("Order Details")}</h4>

          <div className="payment-table">
            <div className="payment-table-row payment-table-header">
              <div className="payment-table-cell">{t("Product Details")}</div>
              <div className="payment-table-cell">{t("Available GPUs")}</div>
              <div className="payment-table-cell">{t("Price Per Unit")}</div>
              <div className="payment-table-cell">{t("Amount")}</div>
              <div className="payment-table-cell">{t("Price")}</div>
            </div>
            <div className="payment-table-row">
              <div className="payment-table-cell">
                <h3>{fakeData.product_name}</h3>
              </div>
              <div className="payment-table-cell">
                <strong>{fakeData.gpu_count}</strong> {t("GPUs")}
              </div>
              <div className="payment-table-cell">
                <strong>USD ${fakeData.price}</strong>
              </div>
              <div className="payment-table-cell">
                <Select
                  defaultValue="1"
                  style={{ width: "auto" }}
                  onChange={(value: string) => setNewAmount(+value)}
                  options={options}
                />
              </div>
              <div className="payment-table-cell">
                <strong>USD ${totalPrice}</strong>
              </div>
            </div>
          </div>

          <ul className="product-details">
            <li className="speed-wrapper">
              <span className="speed-title">
                <ApiOutlined />
                &nbsp;{t("Internet Speed")}:
              </span>
              <div className="speed">
                <span>
                  <ArrowUpOutlined /> {fakeData.internet_upload_speed} Mbps
                </span>
                <span>
                  <ArrowDownOutlined /> {fakeData.internet_download_speed} Mbps
                </span>
              </div>
            </li>
            <li>
              <DesktopOutlined /> {t("GPU RAM")}: {fakeData.gpu_ram} GB
            </li>
            <li>
              <RobotOutlined /> CPU: {fakeData.cpu_name}
            </li>
            <li>
              <SaveOutlined /> {t("Effective RAM")}: {fakeData.effective_ram} GB
            </li>
            <li>
              <DatabaseOutlined /> {t("Disk Storage")}:{" "}
              {fakeData.disk_max_available_storage} GB
            </li>
          </ul>
        </div>
      )}
      <div className="wrapper">
        <div className="payment-methods">
          <h4>{t("Payment Method")}</h4>
          {loading ? (
            <Skeleton active />
          ) : (
            <>
              <Radio.Group
                options={paymentMethods}
                onChange={({ target: { value } }: RadioChangeEvent) => {
                  setPaymentMethod(value);
                }}
                value={paymentMethod}
                optionType="button"
                buttonStyle="solid"
              />

              <h4>{t("Choose Payment Account")}</h4>
              <div
                className={
                  paymentMethod == "card"
                    ? "payment-method"
                    : "payment-method-bank"
                }
              >
                {paymentMethod !== "onlineService" && (
                  <Radio.Group
                    onChange={(e: RadioChangeEvent) => {
                      setPaymentMethodSelect(e.target.value);
                    }}
                    name="paymentMethod"
                  >
                    {paymentMethod == "card" && (
                      <>
                        {cards.map((card) => {
                          return (
                            <Radio value={card.value}>
                              <div key={card.id} className="card-wrapper">
                                <img
                                  src={`./images/cards/${card.type}.svg`}
                                  alt=""
                                />
                                <span>{card.digit}</span>
                              </div>
                            </Radio>
                          );
                        })}
                      </>
                    )}
                    {paymentMethod == "bank" && (
                      <>
                        {banks.map((bank) => {
                          return (
                            <>
                              {bank.confirmed == true && (
                                <Radio value={bank.value}>
                                  <div key={bank.id} className="bank-wrapper">
                                    <Tag
                                      color="success"
                                      icon={<CheckOutlined />}
                                    >
                                      {t("Certified")}
                                    </Tag>
                                    <span>{bank.name}</span>
                                  </div>
                                </Radio>
                              )}
                            </>
                          );
                        })}
                      </>
                    )}
                  </Radio.Group>
                )}

                {paymentMethod == "onlineService" && (
                  <>
                    <Button type="primary">{t("Connect to PayPal")}</Button>
                  </>
                )}

                <Divider />
              </div>
            </>
          )}

          <div className="payment-total">
            <div className="total">
              <div className="total-cal-chart">
                <ul>
                  <li>
                    <span className="cost-title">{t("Product Total")}</span>
                    <span>USD ${totalPrice}</span>
                  </li>
                  <li>
                    <span className="cost-title">{t("Coupon Discount")}</span>
                    <span>USD $0.00</span>
                  </li>
                  <li>
                    <span className="cost-title">
                      {t("Total Amount To Be Paid")}
                    </span>
                    <span className="total-price">USD ${totalPrice}</span>
                  </li>
                </ul>
              </div>
            </div>
            <Button
              type="primary"
              disabled={paymentMethodSelect == ""}
              onClick={() => confirmPayment()}
            >
              {t("Pay")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
