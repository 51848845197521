import { MenuProps } from "antd";
import { Item } from "../interface/common";
import { Buffer } from "buffer";
import * as moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);

export const encryptToken = (token: string) => {
  let base64 = Buffer.from(token);
  return base64.toString("base64");
};

export const decryptToken = (token: string) => {
  let buff = Buffer.from(token, "base64");
  token = buff.toString("utf-8");
  return token;
};

export const calculateTime = (time: number, lang: string) => {
  return (
    <>
      {lang == "en"
        ? moment
            .duration(time, "seconds")
            .format(`d [days] h [hours] m [minutes] s [seconds]`)
        : moment
            .duration(time, "seconds")
            .format(`d[天] h[小時] m[分鐘] s[秒]`)}
    </>
  );
};

export const getCookie = (name: string) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const langs: Item[] = [
  {
    id: "en",
    title: "English",
  },
  { id: "zh_tw", title: "繁體中文" },
];

export const langItems: MenuProps["items"] = [
  {
    key: "en",
    label: "English",
  },
  {
    key: "zh_tw",
    label: "繁體中文",
  },
];
