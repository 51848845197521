import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SettingsInterface } from "../../utility/interface/settings";

const initialState: SettingsInterface = {
  lang: "en",
  menuKey: "1",
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateLang: (state, action: PayloadAction<any>) => {
      return { ...state, lang: action.payload };
    },
    updateMenuKey: (state, action: PayloadAction<any>) => {
      return { ...state, menuKey: action.payload };
    },
    removeSettingsInfo: (state) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateLang, updateMenuKey, removeSettingsInfo } =
  settingsSlice.actions;

export default settingsSlice.reducer;
