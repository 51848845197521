import "../../assets/scss/login-style.scss";
import React, { useState, useEffect } from "react";
import { message } from "antd";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";

import { LangComp } from "../components/LangComp";

import { useAppDispatch } from "../store/hooks";
import { updateToken, updateUserInfo } from "../features/user/userSlice";
import { loginGoogleUser, loginId } from "../server/User";
import { GoogleSignInBtn } from "../../assets/GoogleSignInBtn";

import {
  setAccessTokenSession,
  setRefreshTokenSession,
} from "../utility/common/session-store";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState<any>([]);
  const [profile, setProfile] = useState<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loginViaGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const loginSuccess = () => {
    messageApi.open({
      type: "success",
      content: t("Log In Success!"),
    });
  };

  const loginError = () => {
    messageApi.open({
      type: "error",
      content: t("Log In Failed!"),
    });
  };

  useEffect(() => {
    if (user.access_token) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          loginGoogleUser(user.access_token, res.data.email).then(
            (response) => {
              dispatch(
                updateUserInfo({
                  profile: res.data,
                  user_id: response.data.user_id,
                  is_provider: false,
                  is_initialized: false,
                })
              );
              dispatch(
                updateToken({
                  access_token: response.data.token.access_token,
                  expiration_date: response.data.expiration_date,
                })
              );
              setAccessTokenSession(response.data.token.access_token);
              setRefreshTokenSession(response.data.token.refresh_token);
              setProfile(res.data);
            }
          );
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  return (
    <div className="login-page">
      <main className="login-container">
        {contextHolder}
        <div className="login-card">
          <div className="login-card-left">
            <div className="logo" />
          </div>
          <div className="login-card-right initialize">
            <div className="right-side-top">
              <p>
                {t("First time user?")} &nbsp;
                <button
                  className="sign-up-btn"
                  onClick={() => navigate("/signUp")}
                >
                  {t("Sign Up")}
                </button>
              </p>
            </div>
            <div className="right-side-middle">
              <h1>{t("Welcome back to NeuroWatt!")}</h1>
              <h3>{t("Log in and start using our services")}</h3>

              <div className="form">
                <Formik
                  validateOnMount={true}
                  enableReinitialize={true}
                  initialValues={{}}
                  onSubmit={(values: any) => {
                    loginId(values.email, values.password)
                      .then((res) => {
                        setAccessTokenSession(res.data.token.access_token);
                        setRefreshTokenSession(res.data.token.refresh_token);
                        dispatch(updateUserInfo(res.data));
                        dispatch(
                          updateToken({
                            access_token: res.data.token.access_token,
                            expiration_date: res.data.token.expiration_date,
                          })
                        );
                        if (res.data.is_provider === true) {
                          if (res.data.is_initialized === false) {
                            navigate("/initializeProvider");
                          } else {
                            navigate("/installation");
                          }
                        } else {
                          navigate("/");
                        }
                      })
                      .catch((error) => {
                        loginError();
                      });
                  }}
                >
                  <Form>
                    <div className="label-wrapper">
                      <label htmlFor="email">{t("E-Mail")}</label>
                      <Field name="email" type="email" />
                    </div>
                    <div className="label-wrapper">
                      <label htmlFor="password">{t("Password")}</label>
                      <Field name="password" type="password" />
                    </div>
                    <button className="sign-in-btn" type="submit">
                      {t("Log In")}
                    </button>
                  </Form>
                </Formik>
                <div className="right-side-bottom">
                  <p>
                    {t("Third party login")} &nbsp;
                    {profile ? (
                      <Navigate to="/" />
                    ) : (
                      <GoogleSignInBtn fn={() => loginViaGoogle()} />
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="right-side-bottom">
              <footer>
                <span>
                  {t("Language Selected")}: <LangComp type="text" />
                </span>
                <div className="logo-black" />
                <span>Copyright © 2024 NeuroWatt. All rights reserved.</span>
              </footer>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;
