import "../../../assets/scss/billings-style.scss";

import { Button, ConfigProvider, Divider, Table, TableProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface PaymentHistoryDataType {
  key: string;
  date: string;
  type: string;
  source: string;
  amount: string;
}

export const Billings = () => {
  const { t } = useTranslation();

  const paymentColumns: TableProps<PaymentHistoryDataType>["columns"] = [
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
    },
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("Source"),
      dataIndex: "source",
      key: "source",
    },
    {
      title: t("Price"),
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const paymentData: PaymentHistoryDataType[] = [
    {
      key: "1",
      date: "2024/5/15",
      type: t("Rent"),
      source: t("Credit Card"),
      amount: "$5.05",
    },
    {
      key: "2",
      date: "2024/5/17",
      type: t("Rent"),
      source: t("Credit Card"),
      amount: "$1.05",
    },
  ];

  interface CreditCardDataType {
    key: string;
    name: string;
    number: string;
  }

  const creditCardcolumns: TableProps<CreditCardDataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "編輯",
      key: "action",
      render: (_, record) => <a>{t("Remove")}</a>,
    },
  ];

  const creditCardData: CreditCardDataType[] = [
    {
      key: "1",
      name: "VISA",
      number: "**** **** **** 9932",
    },
    {
      key: "2",
      name: "VISA",
      number: "**** **** **** 1917",
    },
  ];

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
      <p>{t("No Data Yet")}</p>
    </div>
  );

  return (
    <>
      <div className="wrapper">
        <div className="cards-details">
          <h4>{t("Credit CardDebit Card")}</h4>
          <span>
            <Button type="primary">
              <PlusOutlined />
              {t("Add Credit Card")}
            </Button>
          </span>
        </div>
        <Table
          columns={creditCardcolumns}
          dataSource={creditCardData}
          showHeader={false}
          pagination={false}
        />
      </div>
      <div className="wrapper">
        <h4>{t("Billings")}</h4>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table
            columns={paymentColumns}
            dataSource={paymentData}
            showHeader={paymentData.length !== 0}
            tableLayout="fixed"
            pagination={false}
          />
        </ConfigProvider>
      </div>
    </>
  );
};
