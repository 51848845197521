import axiosClient from "../axios";
import { ResultDataModel } from "../../utility/interface/api/result-data";
import { SignUpUserBlobInterface } from "../../utility/interface/api/users/sign-up-user-res";

// change provider pwd

export const changeProviderPwd = (
  email: string,
  new_password: string,
  old_password: string
) => {
  const url = `/changeProviderPwd`;
  const body = {
    email,
    new_password,
    old_password,
  };
  return axiosClient.post<ResultDataModel<any>>(url, body);
};

// signup

export const signupProvider = (signupBlob: SignUpUserBlobInterface) => {
  const url = `/signupProvider`;
  const body = {
    ...signupBlob,
  };
  return axiosClient.post<ResultDataModel<any>>(url, body);
};
