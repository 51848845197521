import "../../../assets/scss/template-card-style.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TemplateCardInterface } from "../../utility/interface/template-card";

import { Input, Radio, Skeleton } from "antd";
import type { RadioChangeEvent } from "antd";
import { TemplateCard } from "../../components/templateCard";
import { getTemplate } from "../../server/GPU";
import { useAppSelector } from "../../store/hooks";

const fakeData: any[] = [
  {
    template_id: "fd2e982e4facaf12347b2918006939d1e06e",
    template_name: "cuda:12.0.1-devel-ubuntu20.04 - docker in docker",
    template_description: "AUTOMATIC1111 t SD Web UI",
    fakeData: true,
    icon_url: "https://storage.googleapis.com/neurowatt/docker_logo.png",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    image_name: "sd-auto",
    image_version: "78",
  },
  {
    template_name: "cuda:12.0.1-devel-ubuntu20.04",
    template_id: "fd2e982e4f111acaf7b2918006939d1e06e",
    template_description:
      "Official Docker images for the machine learning framework TensorFlow. Token=123456t",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url:
      "https://storage.googleapis.com/temporary_neurawatt/tensor-flow.png",
  },
  {
    template_name: "Pytorch 2.2.0 cuda12.1 devel",
    template_id: "e4c5e88bc289f4444eecb0c955c4fe7430d",
    template_description: "AUTOMATIC1111 t SD Web UI",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url: "https://storage.googleapis.com/neurowatt/gpu_jupyter.png",
  },
  {
    template_name: "ollama/ollama",
    template_id: "2aad918293d7eb134e74455564abbacfbd3",
    template_description: "AUTOMATIC1111 t SD Web UI",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url: "https://storage.googleapis.com/neurowatt/docker_logo.png",
  },
  {
    template_name: "cuda:12.0.1-devel-ubuntu20.04 - docker in docker",
    template_id: "9269e7223981d170ea967f755b2cc",
    template_description: "AUTOMATIC1111 t SD Web UI",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url: "https://storage.googleapis.com/neurowatt/docker_logo.png",
  },
  {
    template_name: "Solinscription FAH",
    template_id: "0f32170f143ac35f83f2af59ttad09628d",
    template_description: "AUTOMATIC1111 t SD Web UI",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url: "https://storage.googleapis.com/neurowatt/docker_logo.png",
  },
  {
    template_name: "nvcr.io/nvidia/pytorch",
    template_id: "c7813ee157db8a471fa7c757ced9179eaa",
    template_description:
      "Official Docker images for the machine learning framework TensorFlow. Token=123456t",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url:
      "https://storage.googleapis.com/temporary_neurawatt/tensor-flow.png",
  },
  {
    template_name: "Whisper ASR Webservice",
    template_id: "ttee6ad4922ca464ef404d148f09387622",
    template_description: "AUTOMATIC1111 t SD Web UI",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url: "https://storage.googleapis.com/neurowatt/gpu_jupyter.png",
  },
  {
    template_name: "Tensorflow latest",
    template_id: "ebeb94f1be1f329ef672e92ed8e6575ee4",
    template_description: "AUTOMATIC1111 t SD Web UI",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url: "https://storage.googleapis.com/neurowatt/gpu_jupyter.png",
  },
  {
    template_name: "cuda:12.0.1-devel-ubuntu20.04",
    template_id: "bb3b5abe9e81c8be53a571f177f8b7ec3b",
    template_description: "AUTOMATIC1111 t SD Web UI",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url: "https://storage.googleapis.com/neurowatt/docker_logo.png",
  },
  {
    template_name: "cuda:12.0.1-devel-ubuntu20.04",
    template_id: "adbbt362f982b88a03969802d966a1418b",
    template_description:
      "Official Docker images for the machine learning framework TensorFlow. Token=123456t",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url:
      "https://storage.googleapis.com/temporary_neurawatt/tensor-flow.png",
  },
  {
    template_name: "cuda:12.4.1-devel-ubuntu22.04",
    template_id: "kz3d136eff94df2948d6509158c3ffda54",
    template_description:
      "Official Docker images for the machine learning framework TensorFlow. Token=123456t",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url:
      "https://storage.googleapis.com/temporary_neurawatt/tensor-flow.png",
  },
  {
    template_name: "Pytorch 2.1.2 cuda12.1 devel",
    template_id: "5a1ebad23320932072ebbf1b7105f01bc",
    template_description: "AUTOMATIC1111 t SD Web UI",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url: "https://storage.googleapis.com/neurowatt/docker_logo.png",
  },
  {
    template_name: "Linux Desktop",
    template_id: "980d36b1218fc03b39bde13ff1b77042f",
    template_description: "AUTOMATIC1111 t SD Web UI",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url: "https://storage.googleapis.com/neurowatt/docker_logo.png",
  },
  {
    template_name: "GLX Desktop",
    template_id: "90718f94dbd829e3250d1a4ef10e85778",
    template_description:
      "Official Docker images for the machine learning framework TensorFlow. Token=123456t",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url:
      "https://storage.googleapis.com/temporary_neurawatt/tensor-flow.png",
  },
  {
    template_name: "cuda:12.0.1-devel-ubuntu22.04",
    template_id: "926t9e73981d1701bda6ea967f755b2cc",
    template_description:
      "Official Docker images for the machine learning framework TensorFlow. Token=123456t",
    cli: "docker run -d --name %s -p%d:7860 --gpus '\"device=%s\"' %s:%s",
    fakeData: true,
    icon_url:
      "https://storage.googleapis.com/temporary_neurawatt/tensor-flow.png",
  },
];

// GPU type AMD = 0, NVIDIA = 1

export const Templates = () => {
  const { t } = useTranslation();
  const selectionState = useAppSelector((state: any) => state.selection);
  const [filterValue, setFilterValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [templatesList, setTemplatesList] = useState<TemplateCardInterface[]>(
    []
  );
  const [filteredTemplatesList, setfilteredTemplatesList] = useState<
    TemplateCardInterface[]
  >([]);

  const SwitchTitle = (props: any) => {
    const { value } = props;

    if (value == "all") {
      return <h3>{t("All Templates")}</h3>;
    } else if (value == "popular") {
      return <h3>{t("Popular Templates")}</h3>;
    } else if (value == "recommended") {
      return <h3>{t("Recommended Templates")}</h3>;
    }
    return <></>;
  };

  useEffect(() => {
    if (selectionState.gpu.machine_id) {
      setLoading(true);
      fetchTemplateLst();
      setLoading(false);
      setFilterValue("all");
    }
  }, [selectionState]);

  const fetchTemplateLst = async () => {
    const response = await getTemplate(selectionState.gpu.machine_id);
    const data = await response.data;
    setTemplatesList(data.template); // this give us single source of truth
    setfilteredTemplatesList(data.template);
  };

  const onRadioChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    const list = templatesList;
    setFilterValue(value);
    if (value == "all") {
      setfilteredTemplatesList(list);
    } else {
      const filterList = list.filter((template) => {
        return template.icon_url.includes("nvidia");
      });
      setfilteredTemplatesList(filterList);
    }
  };

  const onChangeInput = (e: any) => {
    const list = templatesList;
    const value = e.target.value;
    const filtered = list.filter((template) => {
      return template.icon_url.includes(value);
    });
    setfilteredTemplatesList(filtered);
  };

  return (
    <div>
      <h3>{t("Template Selection")}</h3>
      <div className="template-sorting">
        <Input
          placeholder={t("Search templates...")}
          size="large"
          onChange={onChangeInput}
        />
        <Radio.Group onChange={onRadioChange} value={filterValue}>
          <Radio value={"all"}>{t("All Templates")}</Radio>
          <Radio value={"popular"}>{t("Popular Templates")}</Radio>
          <Radio value={"recommended"}>{t("Recommended Templates")}</Radio>
        </Radio.Group>
      </div>
      {loading && <Skeleton active />}
      <SwitchTitle value={filterValue} />
      {filteredTemplatesList && (
        <div className="template-card-container">
          {filteredTemplatesList.map((item: any, index: any) => {
            return (
              <TemplateCard
                key={item.template_id}
                template_id={item.template_id}
                template_name={item.template_name}
                template_description={item.template_description}
                icon_url={item.icon_url}
                start_script={item.start_script}
              />
            );
          })}
        </div>
      )}
      <h3>{t("Templates Under Development")}</h3>
      {fakeData && (
        <div className="template-card-container">
          {fakeData.map((item: any, index: any) => {
            return (
              <TemplateCard
                key={item.template_id}
                template_id={item.template_id}
                template_name={item.template_name}
                template_description={item.template_description}
                icon_url={item.icon_url}
                start_script={item.start_script}
                fakeData={item.fakeData}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
