import axiosClient from "../axios";
import { ResultDataModel } from "../../utility/interface/api/result-data";
import { SignUpUserBlobInterface } from "../../utility/interface/api/users/sign-up-user-res";

// login

export const loginGoogleUser = (access_token: string, email: string) => {
  const url = `/loginGoogleUser`;
  const body = {
    access_token,
    email,
  };
  return axiosClient.post<ResultDataModel<any>>(url, body);
};

export const loginId = (email: string, password: string) => {
  const url = `/loginId`;
  const body = {
    email,
    password,
  };
  return axiosClient.post<ResultDataModel<any>>(url, body);
};

// signup

export const signupUser = (signupBlob: SignUpUserBlobInterface) => {
  const url = `/signupUser`;
  const body = {
    ...signupBlob,
  };
  return axiosClient.post<ResultDataModel<any>>(url, body);
};
