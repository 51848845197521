import axiosClient from "../axios";
import { ResultDataModel } from "../../utility/interface/api/result-data";
import { getCookie } from "typescript-cookie";

interface GetFileListReq {
  page_size?: number;
  search_query?: string;
  page_token?: string;
  order_by?: string;
}

interface DownloadFromDriveReq {
  file_id: string;
  destination_path: string;
}

interface UploadToGoogleDriveReq {
  file: FormData;
  file_name: string;
}

export const getGoogleDriveOAuth = (cloudRoute: string) => {
  const url = `getGoogleDriveOAuth?urlName=${cloudRoute}`;
  return axiosClient.get<ResultDataModel<any>>(url);
};

export const getFilesListFromGoogleDrive = (
  state: string,
  params: GetFileListReq
) => {
  const url = `getFilesListFromGoogleDrive?page_size=${params.page_size}`;
  const headers = {
    headers: {
      state,
    },
  };
  return axiosClient.get<ResultDataModel<any>>(url, headers);
};

export const downloadFromGoogleDrive = (
  state: string,
  params: DownloadFromDriveReq
) => {
  const url = `downloadFromGoogleDrive?file_id=${params.file_id}&destination_path=${params.destination_path}`;
  const headers = {
    headers: {
      state,
    },
  };
  return axiosClient.get<ResultDataModel<any>>(url, headers);
};

export const uploadToGoogleDrive = (state: string, params: FormData) => {
  const url = `uploadToGoogleDrive`;
  const headers = {
    headers: {
      state,
      "Content-Type": "multipart/form-data",
    },
  };
  return axiosClient.post<ResultDataModel<any>>(url, params, headers);
};
