import "../../../assets/scss/earnings-style.scss";

import { Button, ConfigProvider, Table, TableProps, Tag } from "antd";
import { useTranslation } from "react-i18next";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

interface PayoutHistoryDataType {
  key: string;
  payoutDate: string;
  paidDate: string;
  source: string;
  amount: string;
}

export const Profits = () => {
  const { t } = useTranslation();

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
      <p>{t("No Data Yet")}</p>
    </div>
  );

  const payoutColumns: TableProps<PayoutHistoryDataType>["columns"] = [
    {
      title: t("Payout Date"),
      dataIndex: "payoutDate",
      key: "payoutDate",
    },
    {
      title: t("Date Paid"),
      dataIndex: "paidDate",
      key: "paidDate",
    },
    {
      title: t("Source"),
      dataIndex: "source",
      key: "source",
    },
    {
      title: t("Price"),
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const payoutData: PayoutHistoryDataType[] = [
    {
      key: "1",
      payoutDate: "2024/5/15",
      paidDate: "2024/5/25",
      source: t("Bank Transfer"),
      amount: "USD $50.00",
    },
    {
      key: "2",
      payoutDate: "2024/5/17",
      paidDate: "2024/5/27",
      source: t("Bank Transfer"),
      amount: "USD $100.00",
    },
    {
      key: "3",
      payoutDate: "2024/5/20",
      paidDate: "2024/5/30",
      source: t("Bank Transfer"),
      amount: "USD $150.00",
    },
    {
      key: "4",
      payoutDate: "2024/6/7",
      paidDate: "2024/6/17",
      source: t("Bank Transfer"),
      amount: "USD $100.00",
    },
    {
      key: "5",
      payoutDate: "2024/6/13",
      paidDate: "2024/6/23",
      source: t("Bank Transfer"),
      amount: "USD $100.00",
    },
  ];

  interface BankAccountDataType {
    key: string;
    name: any;
    confirmed: boolean;
  }

  const bankAccountcolumns: TableProps<BankAccountDataType>["columns"] = [
    {
      title: "Bank Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "confirmed",
      dataIndex: "confirmed",
      key: "confirmed",
      render: (val) => (
        <>
          {val ? (
            <Tag color="success" icon={<CheckOutlined />}>
              {t("Certified")}
            </Tag>
          ) : (
            <Tag color="error" icon={<CloseOutlined />}>
              {t("Uncertified")}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "編輯",
      key: "action",
      render: (_, record) => <a>{t("Edit")}</a>,
    },
  ];

  const bankAccountData: BankAccountDataType[] = [
    {
      key: "1",
      name: t("Yushan Bank"),
      confirmed: true,
    },
    {
      key: "2",
      name: t("China Trust"),
      confirmed: true,
    },
    {
      key: "3",
      name: t("TaiShin Bank"),
      confirmed: false,
    },
  ];

  return (
    <>
      <div className="wrapper">
        <div className="earnings-items">
          <div className="earnings-referrals">
            <div className="earnings-details">
              <h4>{t("Recommendations Earnings")}</h4>
              <Button type="primary">{t("Copy Recommender Link")}</Button>
            </div>
            <ul className="earnings-details-list">
              <li>
                <span>{t("People Recommended")}:</span>
                <span className="amount">0</span>
              </li>
              <li>
                <span>{t("Total Recommendation Earnings")}:</span>
                <span className="amount">$0</span>
              </li>
              <li>
                <span>{t("Recommendation Earnings Uncollected")}:</span>
                <span className="amount">$0</span>
              </li>
              <li>
                <span>{t("Total")}:</span>
                <span className="amount">$0</span>
              </li>
            </ul>
          </div>
          <div className="earnings-payout">
            <div className="payout-details">
              <h4>{t("Payout Settings")}</h4>
              <Button type="primary">{t("Change Payout Method")}</Button>
            </div>
            <strong>
              {t("Bank Account")}（{bankAccountData.length}）
            </strong>
            <Table
              columns={bankAccountcolumns}
              dataSource={bankAccountData}
              showHeader={false}
              pagination={false}
            />
          </div>
        </div>
      </div>
      <div className="wrapper">
        <h4>{t("Payout History")}</h4>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table
            columns={payoutColumns}
            dataSource={payoutData}
            showHeader={payoutData.length !== 0}
            tableLayout="fixed"
            pagination={false}
          />
        </ConfigProvider>
      </div>
    </>
  );
};
