import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SelectionInterface } from "../../utility/interface/selection-res";
import { RentalCardInterface } from "../../utility/interface/rental-card";

const initialState: SelectionInterface = {
  gpu: {},
  instance_id: 0,
  selected_gpu_count: 1,
  template_id: 0,
};

export const selectionSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    chooseGPUSelectionInfo: (
      state,
      action: PayloadAction<RentalCardInterface>
    ) => {
      state.gpu = action.payload;
    },
    updateGPUCountSelectionInfo: (state, action: PayloadAction<number>) => {
      state.selected_gpu_count = Number(action.payload);
    },
    updateInstanceSelectionInfo: (state, action: PayloadAction<number>) => {
      state.instance_id = Number(action.payload);
    },
    updateTemplateSelectionInfo: (state, action: PayloadAction<number>) => {
      state.template_id = Number(action.payload);
    },
    removeSelectionInfo: (state) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  chooseGPUSelectionInfo,
  updateGPUCountSelectionInfo,
  updateInstanceSelectionInfo,
  updateTemplateSelectionInfo,
  removeSelectionInfo,
} = selectionSlice.actions;

export default selectionSlice.reducer;
