import "../../../assets/scss/account-style.scss";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../store/hooks";
import { Descriptions, DescriptionsProps } from "antd";

export const Account = () => {
  const { t } = useTranslation();
  const userState = useAppSelector((state: any) => state.user.profile);

  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: t("Name"),
      children: (
        <>
          {userState.given_name} {userState.family_name}
        </>
      ),
      span: 3,
    },
    {
      key: "2",
      label: t("E-Mail"),
      children: <>{userState.email}</>,
      span: 3,
    },
  ];

  return (
    <div className="wrapper">
      <div className="upper-info">
        <h4>{t("User Info")}</h4>
        <div className="user-details">
          <>
            <img
              style={{ borderRadius: "12%", width: "96px" }}
              src={`${
                userState.picture == ""
                  ? "/images/user/user_icon.png"
                  : userState.picture
              }`}
              alt="user image"
            />
          </>
          <Descriptions items={items} />
        </div>
      </div>
    </div>
  );
};
