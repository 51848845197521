import "../../../assets/scss/payment-style.scss";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Progress, Select } from "antd";

import {
  ApiOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  DatabaseOutlined,
  DesktopOutlined,
  RobotOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  removeSelectionInfo,
  updateGPUCountSelectionInfo,
} from "../../features/selection/selectionSlice";
import { rentGPU } from "../../server/GPU";

export const RentSummary = () => {
  const { t } = useTranslation();
  const userState = useAppSelector((state: any) => state.user);
  const selectionState = useAppSelector((state: any) => state.selection);
  const [loadingTime, setLoadingTime] = useState(0);
  const [successLoading, setSuccessLoading] = useState(false);
  const [rentStatus, setRentStatus] = useState(false);

  const [totalPrice, setNewTotalPrice] = useState<number | string>(
    Number(selectionState.gpu.price)
  ); // 總價
  const [options, setOptions] = useState<any[]>([]); // 可選張數
  const [amount, setNewAmount] = useState<number>(1); // 選擇張數
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const selectedAll = selectionState.gpu.selected_as_whole;

  const startGPURental = async () => {
    setLoading(true);
    setRentStatus(true);
    const response = await rentGPU({
      gpu_count: selectionState.selected_gpu_count,
      machine_id: selectionState.gpu.machine_id,
      template_id: selectionState.template_id,
      user_id: userState.user_id,
    });
    const data = await response.data;
    if (data.code === 0) {
      startTimer();
    } else {
      setRentStatus(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    // this creates the select options for amt
    if (selectionState.gpu) {
      let optionArray = Array.from(
        { length: Number(selectionState.gpu.gpu_count) },
        (_, i) => ({
          value: i + 1,
          label: i + 1,
        })
      );
      setOptions(optionArray);
    }
  }, [selectionState]);

  useEffect(() => {
    let newPrice = Number(selectionState.gpu.price) * amount;
    let decimals = newPrice.toFixed(2);
    setNewTotalPrice(decimals);
  }, [amount]);

  const startTimer = () => {
    let counter = 40;
    setLoadingTime(40);
    const interval = setInterval(() => {
      counter++;
      setLoadingTime(counter);
      if (counter == 100) {
        setSuccessLoading(true);
        setLoading(false);
        clearInterval(interval);
        navigate("/instances");
        dispatch(removeSelectionInfo()); // clears the original selection
      }
    }, 1000);
  };

  useEffect(() => {
    if (selectedAll === undefined) {
      // prevent user from refreshing and loading the summary page
      navigate("/");
    }

    if (selectedAll === false) {
      let count = 1;
      let newPrice = Number(selectionState.gpu.price) * count;
      let decimals = newPrice.toFixed(2);
      dispatch(updateGPUCountSelectionInfo(count));
      setNewAmount(count);
      setNewTotalPrice(decimals);
    }

    if (selectedAll === true) {
      let count = selectionState.gpu.total_gpu_count;
      let newPrice = Number(selectionState.gpu.price) * count;
      let decimals = newPrice.toFixed(2);
      dispatch(updateGPUCountSelectionInfo(count));
      setNewAmount(count);
      setNewTotalPrice(decimals);
    }
  }, []);

  return (
    <>
      <div className="wrapper">
        <h4>{t("Order Details")}</h4>

        <div className="payment-table">
          <div className="payment-table-row payment-table-header">
            <div className="payment-table-cell">{t("Product Details")}</div>
            <div className="payment-table-cell">{t("Available GPUs")}</div>
            <div className="payment-table-cell">{t("Price Per Unit")}</div>
            <div className="payment-table-cell">{t("Amount")}</div>
            <div className="payment-table-cell">{t("Price")}</div>
          </div>
          <div className="payment-table-row">
            <div className="payment-table-cell">
              <h3>{selectionState.gpu.product_name}</h3>
            </div>
            <div className="payment-table-cell">
              <strong>{selectionState.gpu.gpu_count}</strong> {t("GPUs")}
            </div>
            <div className="payment-table-cell">
              <strong>USD ${selectionState.gpu.price}</strong>
            </div>
            <div className="payment-table-cell">
              {selectedAll ? (
                <>{selectionState.selected_gpu_count}</>
              ) : (
                <>
                  <Select
                    defaultValue="1"
                    style={{ width: "auto" }}
                    disabled={selectionState.gpu.gpu_count === 0}
                    onChange={(value: string) => {
                      dispatch(updateGPUCountSelectionInfo(+value));
                      setNewAmount(+value);
                    }}
                    options={options}
                  />
                </>
              )}
            </div>
            <div className="payment-table-cell">
              <strong>
                USD ${totalPrice}
                <b> /{t("Per Hour")}</b>
              </strong>
            </div>
          </div>
        </div>

        <ul className="product-details">
          <li className="speed-wrapper">
            <span className="speed-title">
              <ApiOutlined />
              &nbsp;{t("Internet Speed")}:
            </span>
            <div className="speed">
              <span>
                <ArrowUpOutlined /> {selectionState.gpu.internet_upload_speed}{" "}
                Mbps
              </span>
              <span>
                <ArrowDownOutlined />{" "}
                {selectionState.gpu.internet_download_speed} Mbps
              </span>
            </div>
          </li>
          <li>
            <DesktopOutlined /> {t("GPU RAM")}: {selectionState.gpu.gpu_ram} GB
          </li>
          <li>
            <RobotOutlined /> CPU: {selectionState.gpu.cpu_name}
          </li>
          <li>
            <SaveOutlined /> {t("Effective RAM")}:{" "}
            {selectionState.gpu.effective_ram} GB
          </li>
          <li>
            <DatabaseOutlined /> {t("Disk Storage")}:{" "}
            {selectionState.gpu.disk_max_available_storage}
            GB
          </li>
        </ul>
      </div>
      <div className="wrapper">
        <div className="rent-status">
          <div className="rental-details">
            <div className="rent">
              {loading && (
                <>
                  <p>{t("GPU Starting")}</p>
                  <br />
                  <Progress
                    percent={loadingTime}
                    strokeColor={{
                      "0%": "#A09499",
                      "25%": "#6B302D",
                      "50%": "#532334",
                      "75%": "#181621",
                      "100%": "#A09499",
                    }}
                    percentPosition={{ align: "end", type: "outer" }}
                    status={successLoading == true ? "success" : "normal"}
                  />
                </>
              )}
            </div>
            {!loading && (
              <Button
                type="primary"
                disabled={selectionState.gpu.gpu_count === 0}
                onClick={() => startGPURental()}
              >
                {t("Rent")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
