import "../../../assets/scss/instance-details-style.scss";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  BranchesOutlined,
  CopyOutlined,
  DesktopOutlined,
  HistoryOutlined,
  InteractionOutlined,
  MoneyCollectOutlined,
  RobotOutlined,
} from "@ant-design/icons";

import { Button, Divider, Input, Modal, Skeleton, Spin, message } from "antd";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getInstanceSSHCommand,
  stopGPURent,
  updateInstancePublicKey,
} from "../../server/GPU";
import { calculateTime } from "../../utility/common/common";
import {
  chooseInstance,
  updatePublicKey,
} from "../../features/instance/instanceSlice";
import { Field, Form, Formik } from "formik";

const contentStyle: React.CSSProperties = {
  padding: 50,
};

const content = <div style={contentStyle} />;

export const InstanceDetail = () => {
  const { t } = useTranslation();
  const instanceState = useAppSelector((state: any) => state.instance);
  const userState = useAppSelector((state: any) => state.user);
  const settingsState = useAppSelector((state: any) => state.settings);
  const [loading, setLoading] = useState(false);
  const [rentalSummary, setRentalSummary] = useState<any>(null);
  const [finalTimeConsumed, setFinalTimeConsumed] = useState<number>(0);
  const [stoppedRental, setStoppedRental] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isSSHModalOpen, setIsSSHModalOpen] = useState(false);
  const [SSHCommand, setSSHCommand] = useState("");
  // const [checkOutModal, setCheckOutModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // 結束租用時，需要抓最新的時間去計算
  const stopGPURental = async () => {
    setLoading(true);
    setStoppedRental(true);
    const response = await stopGPURent({
      instance_id: instanceState.instance_id,
      machine_id: instanceState.machine_id,
      user_id: userState.user_id,
    });
    const data = await response.data;
    if (data.code === 0) {
      dispatch(
        chooseInstance({
          ...instanceState,
          time_consumed: data.time_consumed,
        })
      );
      setFinalTimeConsumed(data.time_consumed);
      setLoading(false);
      setRentalSummary(data);
    }
  };
  // const openInstance = () => {
  //   setCheckOutModal(true);
  // };

  const calculateTotal = (unit_price: number, time: number) => {
    let total = 0;
    total = unit_price * time * instanceState.gpu_count;
    let newTotal = total.toFixed(2);
    return newTotal;
  };

  const calculatePerHour = (unit_price: number, gpu_count: number) => {
    let total = 0;
    total = unit_price * gpu_count;
    let newTotal = total.toFixed(2);
    return newTotal;
  };

  const startSSHInstance = () => {
    getInstanceSSHCommand(userState.user_id, instanceState.instance_id)
      .then((res) => setSSHCommand(res.data.ssh_cmd))
      .catch((err) => console.log(err));
  };

  const openSSHInstanceModal = () => {
    setIsSSHModalOpen(true);
  };

  const handleSSHOk = () => {
    setIsSSHModalOpen(false);
  };

  const handleSSHCancel = () => {
    setIsSSHModalOpen(false);
  };

  const addedInstanceKeySuccess = () => {
    messageApi.success(t("Instance Key Added Success!"), 2.5);
  };

  const addedInstanceKeyFailed = () => {
    messageApi.error(t("Instance Key Added Failed!"), 2.5);
  };

  const paymentProgress = () => {
    messageApi
      .open({
        type: "loading",
        content: t("Payment In Progress..."),
        duration: 2.5,
      })
      .then(() => message.success(t("Payment Success!"), 2.5))
      .then(() => navigate("/"));
  };

  return (
    <>
      <div className="wrapper">
        {contextHolder}
        <div className="instance-detail-top">
          {loading ? (
            <div className="instance-loading-wrapper">
              <Spin size="large">{content}</Spin>
              <span>{t("Ending service and calculating final cost...")}</span>
            </div>
          ) : (
            <div className="instance-detail-card">
              <ul className="instance-detail-card-details">
                <li>
                  <DesktopOutlined /> ID: {instanceState.instance_id}
                </li>
                <li>
                  <RobotOutlined /> {t("Machine")} ID:{" "}
                  {instanceState.machine_id}
                </li>
                <li>
                  <BranchesOutlined /> {t("GPU Count")}:{" "}
                  {instanceState.gpu_count} GPU
                </li>{" "}
                <li>
                  <BranchesOutlined /> {t("GPU Type")}:{" "}
                  {instanceState.product_name}
                </li>
                <li>
                  <HistoryOutlined /> {t("Time Used")}:{" "}
                  {calculateTime(
                    instanceState.time_consumed,
                    settingsState.lang
                  )}
                </li>
                <li>
                  <MoneyCollectOutlined /> {t("Price")}: USD ${" "}
                  {calculatePerHour(
                    instanceState.price,
                    instanceState.gpu_count
                  )}
                  /{t("hours")}
                </li>
                <li>
                  <InteractionOutlined /> {t("Total Amount To Be Paid")}: USD ${" "}
                  {calculateTotal(
                    instanceState.price,
                    Number(instanceState.time_consumed) / 3600 // cal to min
                  )}{" "}
                  {settingsState.lang !== "en" && "元"}
                </li>
              </ul>
            </div>
          )}
          <div className="options-container">
            <Button
              type="primary"
              disabled={stoppedRental}
              onClick={() => {
                stopGPURental();
              }}
            >
              {t("End Rental")}
            </Button>
            {instanceState.service_url && (
              <Button
                disabled={stoppedRental}
                href={instanceState.service_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Use Services")}
              </Button>
            )}
            {instanceState.ssh_url && instanceState.rent_all === false && (
              <Button
                href={instanceState.ssh_url}
                target="_blank"
                rel="noopener noreferrer"
                disabled={stoppedRental}
              >
                {t("Start SSH Service")}
              </Button>
            )}
            {instanceState.rent_all === true && (
              <>
                {instanceState.public_key === "" && (
                  <Button
                    onClick={() => {
                      openSSHInstanceModal(); // 開啟增加 key 的 modal
                    }}
                  >
                    {t("Start SSH Web Services")}
                  </Button>
                )}
                {instanceState.public_key !== "" && (
                  <Button
                    onClick={() => {
                      startSSHInstance(); // 直接打複製 cmd 的 api
                    }}
                  >
                    {t("Start SSH Web Services")}
                  </Button>
                )}
              </>
            )}
            {SSHCommand && (
              <Input
                value={SSHCommand}
                suffix={
                  <CopyOutlined
                    onClick={() => navigator.clipboard.writeText(SSHCommand)}
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                    }}
                  />
                }
              />
            )}
          </div>
        </div>
        {rentalSummary && (
          <>
            <Divider />
            <div className="rental-summary">
              <div className="payment-total">
                <div className="total">
                  <div className="total-cal-chart">
                    <ul>
                      <li>
                        <span className="cost-title">{t("Product Price")}</span>
                        <span>
                          USD ${rentalSummary.unit_price} /{t("hours")}
                        </span>
                      </li>
                      <li>
                        <span className="cost-title">GPU {t("GPU Count")}</span>
                        <span>
                          {instanceState.gpu_count} {t("GPUs")}
                        </span>
                      </li>
                      <li>
                        <span className="cost-title">
                          {t("Total Time Used")}
                        </span>
                        <span>
                          {calculateTime(finalTimeConsumed, settingsState.lang)}
                        </span>
                      </li>
                      <li>
                        <span className="cost-title">
                          {t("Total Amount To Be Paid")}
                        </span>
                        <span className="total-price">
                          USD $
                          {calculateTotal(
                            rentalSummary.unit_price,
                            Number(finalTimeConsumed) / 3600
                          )}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <Button type="primary" onClick={() => paymentProgress()}>
                  {t("Pay")}
                </Button>
              </div>
            </div>
            {/* <AccessInstanceModal
              modalState={checkOutModal}
              onOkFn={() => setCheckOutModal(false)}
              onCancelFn={() => setCheckOutModal(false)}
              info={instanceState.service_url}
            /> */}
          </>
        )}
      </div>
      <Modal
        title={t("Please Add a Public Key...")}
        open={isSSHModalOpen}
        onOk={handleSSHOk}
        onCancel={handleSSHCancel}
        footer={null}
      >
        <Formik
          validateOnMount={true}
          enableReinitialize={true}
          initialValues={{}}
          onSubmit={(values: any) => {
            const data = {
              ...values,
              instance_id: instanceState.instance_id,
            };
            setLoading(true);
            dispatch(updatePublicKey(values.public_key));
            updateInstancePublicKey(userState.user_id, data)
              .then((res) => {
                if (res.data.msg == "success") {
                  setLoading(false);
                  addedInstanceKeySuccess();
                  handleSSHCancel();
                  startSSHInstance();
                }
              })
              .catch((res) => {
                setLoading(false);
                addedInstanceKeyFailed();
                handleSSHCancel();
              });
          }}
        >
          <Form className="add-key-form">
            {loading ? (
              <Skeleton active />
            ) : (
              <Field name="public_key" as="textarea" />
            )}
            <button className="add-key-btn" disabled={loading} type="submit">
              {t("Add Public Key")}
            </button>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};
