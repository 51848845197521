import "./App.scss";
import "./i18next";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Main from "./app/pages/Main";
import Login from "./app/pages/Login";
import SignUp from "./app/pages/SignUp";
import InitializeProvider from "./app/pages/InitializeProvider";
import SignUpProvider from "./app/pages/SignUpProvider";

import { useAppSelector } from "./app/store/hooks";
const App: React.FC = () => {
  const settingsState = useAppSelector((state: any) => state.settings);
  const { i18n } = useTranslation();

  const changeLanguageHandler = (lang: string) => {
    let currLang = lang ?? "en";
    i18n.changeLanguage(currLang);
  };

  useEffect(() => {
    changeLanguageHandler(settingsState.lang);
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Main />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signUp" element={<SignUp />}></Route>
          <Route path="/signUpProvider" element={<SignUpProvider />}></Route>
          <Route
            path="/initializeProvider"
            element={<InitializeProvider />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
