import "../../../assets/scss/instance-card-style.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { InstanceCard } from "../../components/instanceCard";
import { InstanceCardInterface } from "../../utility/interface/instance-card";
import { getGPUInstnace } from "../../server/GPU";

import { Empty, Skeleton } from "antd";
import { useAppSelector } from "../../store/hooks";

export const Instances = () => {
  const { t } = useTranslation();
  const userState = useAppSelector((state: any) => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [instancesList, setInstancesList] = useState<InstanceCardInterface[]>(
    []
  );
  useEffect(() => {
    if (userState.user_id) {
      setLoading(true);
      fetchInstanceList();
    }
  }, [userState]);

  const fetchInstanceList = async () => {
    const response = await getGPUInstnace(userState.user_id);
    const data = await response.data;
    setLoading(false);
    if (data.gpu_instance !== null) {
      setInstancesList(data.gpu_instance.reverse());
    } else {
      setInstancesList([]);
    }
  };

  return (
    <>
      {loading && <Skeleton active />}
      {instancesList && instancesList.length > 0 ? (
        <div className="instance-card-container">
          {instancesList.map((item: any, index: any) => {
            return (
              <InstanceCard
                key={index}
                idx={index}
                instance_id={item.instance_id}
                machine_id={item.machine_id}
                gpu_count={item.gpu_count}
                time_consumed={item.time_consumed}
                price={item.price}
                service_url={item.service_url}
                ssh_url={item.ssh_url}
                product_name={item.product_name}
                public_key={item.public_key}
                rent_all={item.rent_all}
              />
            );
          })}
        </div>
      ) : (
        <>
          {loading == false && (
            <div className="instance-card-none">
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>{t("There are currently no active machines.")}</>
                }
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
