import { SessionFieldEnum } from "../enum/session-field.enum";
import { ErrorEnum } from "../enum/error.enum";
import { Buffer } from "buffer";

export const setAccessTokenSession = (token: string) => {
  let base64 = Buffer.from(token);
  sessionStorage.setItem(
    SessionFieldEnum.AccessToken,
    base64.toString("base64")
  );
};

export const getAccessTokenSession = () => {
  let token = "";
  let sessionItem = sessionStorage.getItem(SessionFieldEnum.AccessToken);
  if (sessionItem !== null) {
    let buff = Buffer.from(sessionItem!, "base64");
    token = buff.toString("utf-8");
  } else {
    throw ErrorEnum.None;
  }
  return token;
};

export const setRefreshTokenSession = (token: string) => {
  let base64 = Buffer.from(token);
  sessionStorage.setItem(
    SessionFieldEnum.RefreshToken,
    base64.toString("base64")
  );
};

export const getRefreshTokenSession = () => {
  let token = "";
  let sessionItem = sessionStorage.getItem(SessionFieldEnum.RefreshToken);
  if (sessionItem !== null) {
    let buff = Buffer.from(sessionItem!, "base64");
    token = buff.toString("utf-8");
  } else {
    throw ErrorEnum.None;
  }
  return token;
};

export const clearAllSessions = () => {
  sessionStorage.removeItem(SessionFieldEnum.AccessToken);
  sessionStorage.removeItem(SessionFieldEnum.RefreshToken);
};
