import { Divider, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "../../../assets/scss/installation-style.scss";

import {
  AlertOutlined,
  ApiOutlined,
  CoffeeOutlined,
  RobotOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { useAppSelector } from "../../store/hooks";
import { getHostInstallUrl } from "../../server/Installation";

export const Installation = () => {
  const { t } = useTranslation();
  const userState = useAppSelector((state: any) => state.user);
  const [loading, setLoading] = useState<boolean>(true);
  const [installationUrl, setInstallationUrl] = useState<any>({});
  const navigate = useNavigate();

  const fetchInstallation = async () => {
    const response = await getHostInstallUrl();
    const data = await response.data;
    setInstallationUrl(data);
  };

  useEffect(() => {
    if (userState.provider_id) {
      if (userState.is_initialized === false) {
        navigate("/InitializeProvider");
      }
      setLoading(false);
    }
  }, [userState]);

  useEffect(() => {
    fetchInstallation();
  }, []);

  return (
    <div className="wrapper">
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="installation-info">
          <h2>
            <RobotOutlined /> {t("Installation Guide")}
          </h2>
          <h4>
            <UserOutlined /> {t("Provider ID")}: {userState.provider_id}
          </h4>
          <h4>
            <ApiOutlined /> {t("System Requirements")}: Ubuntu 22.04
          </h4>
          <Divider />
          <h4>
            <CoffeeOutlined /> {t("Download")}{" "}
            <a href={`${installationUrl.benchmark_url}`} target="_blank">
              {t("Benchmark tools")}
            </a>
          </h4>
          <p>
            {t(
              "Benchmark tools will evaluate the performance of GPUs of host."
            )}
            :<code>$sudo bash benchmark.sh provider_id</code>
          </p>
          <p>
            <AlertOutlined /> <strong>provider_id</strong>{" "}
            {t("is the Provider ID displayed on the top of the page.")}
          </p>
          <Divider />
          <h4>
            <ApiOutlined /> {t("Download")}{" "}
            <a href={`${installationUrl.install_url}`} target="_blank">
              {t("Host Agent tools")}
            </a>
          </h4>
          <p>
            {t(
              "Host Agent tools will install the tools that manage the connections of GPU rental and Machine Status reports. Please run command below"
            )}
            :<code>$sudo bash install.sh provider_id</code>
          </p>
          <Divider />
          <p>
            {t(
              "To check if the host has joined to the GPU Power System, please go to the provider dashboard and the Machine Status Page."
            )}
          </p>
        </div>
      )}
    </div>
  );
};
