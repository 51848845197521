import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import zhTWCommon from "./assets/locales/zh_tw/common.json";
import enUSCommon from "./assets/locales/en/common.json";

i18n.use(initReactI18next).init({
  ns: ["common"],
  resources: {
    zh_tw: {
      common: zhTWCommon,
    },
    en: {
      common: enUSCommon,
    },
  },
  lng: "en",
});
