import axiosClient from "../axios";
import { ResultDataModel } from "../../utility/interface/api/result-data";

export const getHostInstallUrl = () => {
  const url = `/hostInstallUrl`;
  return axiosClient.get<ResultDataModel<any>>(url);
};

export const getHostStatus = (provider_id: number) => {
  const url = `/hostStatus`;
  let body = { provider_id };
  return axiosClient.post<ResultDataModel<any>>(url, body);
};
