import "../../../assets/scss/lang-comp-style.scss";
import { useEffect, useState } from "react";
import { Dropdown, MenuProps, Space } from "antd";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { updateLang } from "../../features/settings/settingsSlice";
import { langItems, langs } from "../../utility/common/common";

import { GlobalOutlined } from "@ant-design/icons";

interface LangCompType {
  type?: string;
}

export const LangComp = (props: LangCompType) => {
  const settingsState = useAppSelector((state: any) => state.settings);
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState("en");
  const { type } = props;
  const dispatch = useAppDispatch();

  const selectLang = (lang: string) => {
    dispatch(updateLang(lang));
    setSelectedLang(lang);
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    selectLang(e.key);
    i18n.changeLanguage(e.key);
  };

  const getCurrLang = () => {
    const found = langs.find((item) => item.id == selectedLang);
    return found?.title;
  };

  useEffect(() => {
    if (settingsState.lang !== selectedLang) {
      setSelectedLang(settingsState.lang);
    }
  }, []);

  return (
    <Dropdown
      className="lang-drop"
      menu={{ items: langItems, onClick: handleMenuClick }}
      trigger={["click"]}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>{type === "text" ? getCurrLang() : <GlobalOutlined />}</Space>
      </a>
    </Dropdown>
  );
};
