import "../../../assets/scss/rental-card-style.scss";
import { Tag } from "antd";

import {
  BulbOutlined,
  CheckCircleOutlined,
  CoffeeOutlined,
  MinusCircleOutlined,
  RobotOutlined,
} from "@ant-design/icons";

import { useAppSelector } from "../../store/hooks";
import { MachineCardInterface } from "../../utility/interface/machine-card";
import { calculateTime } from "../../utility/common/common";
import { useTranslation } from "react-i18next";

export const MachineCard = (props: MachineCardInterface) => {
  const { t } = useTranslation();
  const { machine_id, health_status, time_living } = props;
  const settingsState = useAppSelector((state: any) => state.settings);

  return (
    <>
      <div className="rental-card">
        <ul className="rental-card-details">
          <li>
            <RobotOutlined /> {t("Machine")} ID: {machine_id}
          </li>
          <li>
            <BulbOutlined /> {t("Machine Status")}:{" "}
            {health_status === 0 ? (
              <Tag icon={<MinusCircleOutlined />} color="default">
                {t("Inactive")}
              </Tag>
            ) : (
              <Tag icon={<CheckCircleOutlined />} color="success">
                {t("Active")}
              </Tag>
            )}
          </li>
          <li>
            <CoffeeOutlined /> {t("Active Time")}:{" "}
            {calculateTime(time_living, settingsState.lang)}
          </li>
        </ul>
      </div>
    </>
  );
};
