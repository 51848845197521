import "../../../assets/scss/instance-card-style.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Empty, Skeleton } from "antd";
import { useAppSelector } from "../../store/hooks";
import { getHostStatus } from "../../server/Installation";
import { MachineCard } from "../../components/machineCard";
import { MachineCardInterface } from "../../utility/interface/machine-card";

export const MachineStatus = () => {
  const { t } = useTranslation();
  const userState = useAppSelector((state: any) => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [machineList, setMachineList] = useState<[]>([]);
  useEffect(() => {
    if (userState.provider_id) {
      setLoading(true);
      fetchMachineList();
    }
  }, [userState]);

  const fetchMachineList = async () => {
    const response = await getHostStatus(userState.provider_id);
    const res = await response;
    setLoading(false);
    if (res !== undefined) {
      setMachineList(res.data.provider_host);
    } else {
      setMachineList([]);
    }
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {machineList.length > 0 ? (
        <div className="instance-card-container">
          {machineList.map((item: MachineCardInterface) => {
            return (
              <MachineCard
                key={item.machine_id}
                machine_id={item.machine_id}
                health_status={item.health_status}
                time_living={item.time_living}
              />
            );
          })}
        </div>
      ) : (
        <div className="instance-card-none">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<>{t("No Machines Yet")}</>}
          />
        </div>
      )}
    </>
  );
};
